import { Tab } from '@headlessui/react';

import ProjectStateToggle from '@/components/ProjectStateToggle';
import Topbar from '@/components/topbar';
import { ORGANIZATION_TYPES } from '@/utils/constants';
import { useSelector } from 'react-redux';
import { getActiveOrganization } from '@/redux/features/organization';
import React, { useEffect, useState } from 'react';
import { getClassNames, organizationsList } from '@/utils/helpers';
import DashboardOverview from '@/components/dashboardOverview';
import PartnerNetwork from './components/partnerNetwork';
import { DemoGuidePopoverContext } from '@/context/demoGuidePopover';
import MerchantPerformance from '@/components/MerchantPerformance';
import MerchantInfraStructure from '@/components/MerchantInfraStructure';
const PartnerDashboard = () => {
  const [selectOrganization, setSelectOrganization] = useState('Overview');
  const [partnersListData, setPartnersListData] = useState<
    {
      id: string;
      name: string;
      organization_type: string;
      image: string;
    }[]
  >([]);
  const { showExploreDemo } = React.useContext(DemoGuidePopoverContext);
  const activeOrganization = useSelector(getActiveOrganization);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabOptions = ['Network', 'Performance', 'Infrastructure'];

  useEffect(() => {
    setPartnersListData(
      organizationsList.filter(
        (organization) => organization.organization_type === ORGANIZATION_TYPES.MERCHANT
      )
    );
  }, []);

  return (
    <div
      className={getClassNames(
        'flex w-full flex-col h-screen bg-white overflow-hidden',
        showExploreDemo ? 'blur-sm' : ''
      )}
    >
      <Topbar title={''}>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center">
            <DashboardOverview
              project={{
                label: selectOrganization,
                value: selectOrganization,
              }}
              projectList={[
                {
                  label: 'Overview',
                  value: 'Overview',
                },
                ...organizationsList
                  .filter(
                    (organization) =>
                      organization.organization_type === ORGANIZATION_TYPES.MERCHANT
                  )
                  .map((organization) => {
                    return { label: organization.name, value: organization.name };
                  }),
              ]}
              handleProjectSelect={(value) => {
                setSelectOrganization(value.label);
              }}
            />
          </div>
          <div className="relative">
            {activeOrganization?.organization_type !== ORGANIZATION_TYPES.MERCHANT &&
              partnersListData.length && (
                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                  <Tab.List className=" flex gap-2">
                    {tabOptions?.map((tabTitle) => (
                      <Tab key={tabTitle}>
                        {({ selected }) => (
                          <div
                            className={getClassNames(
                              'px-4 py-2 border border-grey-stroke-primary rounded font-medium',
                              selected
                                ? 'text-trud-black bg-grey-stroke-primary'
                                : 'text-grey-content-secondary'
                            )}
                          >
                            {tabTitle}
                          </div>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </Tab.Group>
              )}
          </div>
          {activeOrganization &&
            activeOrganization?.organization_type === ORGANIZATION_TYPES.MERCHANT &&
            partnersListData.length && (
              <div className="flex items-center mr-4">
                <ProjectStateToggle className="rounded" value={'1'} onChange={() => {}} />
              </div>
            )}
          <div></div>
        </div>
      </Topbar>
      <div className="relative flex-1 flex flex-col h-full overflow-auto px-8 py-7">
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="hidden">
            <Tab></Tab>
            <Tab></Tab>
            <Tab></Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <PartnerNetwork selectedOrganization={selectOrganization} />
            </Tab.Panel>
            <Tab.Panel>
              <MerchantPerformance />
            </Tab.Panel>
            <Tab.Panel>
              <MerchantInfraStructure />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default PartnerDashboard;
