// Assets
import { ReactComponent as MenuIcon } from "@icons/menu.svg";

const ControlButtons = () => {
  return (
    <div className="flex flex-col gap-3 fixed right-8 bottom-20">
      <a href="https://experience.worldlineiec.trudenty.com/menu">
        <MenuIcon className="cursor-pointer" />
      </a>
    </div>
  );
};

export default ControlButtons;
