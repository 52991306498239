import { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { authContext } from '@context/auth';
import CookieContextProvider from '@context/cookie';
import DemoGuidePopoverContextProvider from '@context/demoGuidePopover';
import SideBar from '@components/sidebar';
import { Dashboard, DashboardNoData } from '@pages/dashboard';
import Login from '@pages/auth/login';
import Signup from '@pages/auth/signup';
import SignupSuccess from '@pages/auth/signupSuccess';
import { Users, User } from '@pages/users';
import Organization from '@pages/organization';
import { Groups, Team, RolesAndPermissions } from '@pages/groups';
import {
  CreateProject,
  EditProject,
  GetStartedSTI,
  ShopperConfiguration,
  ManageProjects,
  GetStartedCheckoutAndRefund,
} from '@pages/projects';
import {
  PaymentSetup,
  MockBankLogin,
  MockBankConsentAuthorization,
  SetupSuccess,
  SetupDeclined,
} from '@pages/projects/payment-setup';
import UseCaseSelect from '@pages/useCaseSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveOrganization,
  setActiveOrganization,
} from './redux/features/organization';
import { organizationsList } from './utils/helpers';
import { ORGANIZATION_TYPES } from './utils/constants';
import IRootState from './redux/store';
import PartnerUsers from './pages/users/components/PartnerUsers';
import OrganizationList from './pages/dashboard/components/OrganizationList';

const App = () => {
  const { isAuthenticated, hasSetup } = useContext(authContext);
  // const isAuthenticated = localStorage.getItem('isAuthenticated')
  const activeOrganization = useSelector(getActiveOrganization);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeOrganization) {
      dispatch(
        setActiveOrganization(
          organizationsList?.find(
            (organization) =>
              organization?.organization_type === ORGANIZATION_TYPES.MERCHANT
          )
        )
      );
    }
  }, []);

  return (
    <Router>
      <CookieContextProvider>
        <DemoGuidePopoverContextProvider>
          {!isAuthenticated ? (
            <div className="h-screen bg-white overflow-y-hidden">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/signup-success" element={<SignupSuccess />} />
                <Route path="/enrollment" element={<Login />} />
                <Route path="/use-case" element={<UseCaseSelect />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            </div>
          ) : (
            <div className="bg-body flex w-full h-screen overflow-hidden">
              <SideBar />
              <Routes>
                <Route path="/use-case/:product" element={<UseCaseSelect />} />
                <Route path="/use-case" element={<UseCaseSelect />} />
                <Route
                  path="/home"
                  element={hasSetup ? <Dashboard /> : <DashboardNoData />}
                />
                <Route path="users">
                  <Route
                    index
                    element={
                      activeOrganization?.organization_type ===
                      ORGANIZATION_TYPES.MERCHANT ? (
                        <Users />
                      ) : (
                        <PartnerUsers />
                      )
                    }
                  />
                  <Route path=":id" element={<User />} />
                </Route>
                <Route path="groups">
                  <Route index element={<Groups />} />
                  <Route path=":id" element={<Team />} />
                  <Route path=":id/roles" element={<RolesAndPermissions />} />
                </Route>
                <Route path="projects">
                  <Route
                    index
                    element={
                      hasSetup ? <ManageProjects /> : <Navigate to="new" replace />
                    }
                  />
                  <Route path="new" element={<CreateProject />} />
                  <Route path="edit" element={<EditProject />} />
                  <Route path="sti/get-started" element={<GetStartedSTI />} />
                  <Route
                    path="cr/get-started"
                    element={<GetStartedCheckoutAndRefund />}
                  />
                  <Route path="configure" element={<ShopperConfiguration />} />
                  <Route path="manage" element={<ManageProjects />} />
                </Route>
                <Route path="setup-payment">
                  <Route index element={<PaymentSetup />} />
                  <Route path="bank-login" element={<MockBankLogin />} />
                  <Route path="bank-consent" element={<MockBankConsentAuthorization />} />
                  <Route path="setup-success" element={<SetupSuccess />} />
                  <Route path="setup-declined" element={<SetupDeclined />} />
                </Route>
                <Route
                  path="/organization"
                  element={
                    activeOrganization?.organization_type ===
                    ORGANIZATION_TYPES.MERCHANT ? (
                      <Organization />
                    ) : (
                      <OrganizationList />
                    )
                  }
                />
                <Route path="*" element={<Navigate to="/home" replace />} />
              </Routes>
            </div>
          )}
        </DemoGuidePopoverContextProvider>
      </CookieContextProvider>
    </Router>
  );
};

export default App;
