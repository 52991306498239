import { getClassNames } from '@/utils/helpers';
import { Tab } from '@headlessui/react';
import { useState } from 'react';
import { ORGANIZATION_TYPES, roleAndPermissionData, ROLES } from '@/utils/constants';
import RoleAndPermissionsDescription from './RoleAndPermissionsDescription';

interface IRoleType {
  id: string;
  name: string;
  organization_type: string;
}

const RoleAndPermission = () => {
  const [role, setRole] = useState<number>(0);
  const roleData: IRoleType[] = Object.values(ROLES).map((role, index) => {
    return {
      id: (index + 1).toString(),
      name: role,
      organization_type: ORGANIZATION_TYPES.PARTNER,
    };
  });

  return (
    <div className="px-8 pb-6">
      <div className="border border-stroke bg-white">
        <div className="relative flex w-full">
          <div className="bg-body-variant2 p-5 w-[226px]">
            <Tab.Group
              selectedIndex={role}
              onChange={(currentRole) => {
                setRole(currentRole);
              }}
            >
              <span className="block mb-3 text-grey-content-tertiary text-base font-medium">
                Roles
              </span>
              <Tab.List className="flex flex-col">
                {roleData.map((roleName) => {
                  return (
                    <Tab key={roleName?.id}>
                      {({ selected }) => (
                        <div
                          className={getClassNames(
                            'px-4 py-2.5 rounded text-left',
                            selected
                              ? 'text-trud-black bg-grey-stroke-primary'
                              : 'text-grey-content-secondary'
                          )}
                        >
                          {roleName.name}
                        </div>
                      )}
                    </Tab>
                  );
                })}
              </Tab.List>
            </Tab.Group>
          </div>
          <div className="bg-white p-10 pb-5 w-[calc(100%-226px)]">
            <Tab.Group selectedIndex={role}>
              <Tab.List className="hidden">
                <Tab></Tab>
                <Tab></Tab>
                <Tab></Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <RoleAndPermissionsDescription
                    title={roleData[role]?.name ?? ''}
                    // roleId={roleData[role]?.id ?? ''}
                    // permissionData={roleAndPermissionData[ROLES.ADMIN]}
                    // organizationId={organizationId ?? ''}
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <RoleAndPermissionsDescription
                    title={roleData[role]?.name ?? ''}
                    // roleId={roleData[role]?.id ?? ''}
                    // permissionData={roleAndPermissionData[ROLES.EDITOR]}
                    // organizationId={organizationId ?? ''}
                  />
                </Tab.Panel>
                <Tab.Panel>
                  <RoleAndPermissionsDescription
                    title={roleData[role]?.name ?? ''}
                    // roleId={roleData[role]?.id ?? ''}
                    // permissionData={roleAndPermissionData[ROLES.EDITOR]}
                    // organizationId={organizationId ?? ''}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleAndPermission;
